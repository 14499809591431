import { Table } from '@octano/global-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import useColumns from '../hooks/useColumns';
import { useFinantialOthersState } from '../hooks/useFinantialOthersState';
import { PetitionPaymentTypeId } from '../type';

function FinantialOtherPetitions() {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );
  const {
    petitionTypesState,
    setPetitionTypesState,
  } = useFinantialOthersState();

  const [tableKey, setTableKey] = useState(0);

  const columns = useColumns({
    showPaymentType: true,
    translateKey: 'petitionTypes',
    onChangeValue: (typeId, fieldName, fieldValue) => {
      setPetitionTypesState((prev) =>
        prev.map((c) => {
          switch (fieldName) {
            case 'paymentAmount':
              return c.id === typeId
                ? { ...c, [fieldName]: fieldValue as number }
                : c;
            case 'paymentType':
            default:
              return c.id === typeId
                ? {
                    ...c,
                    [fieldName]: fieldValue,
                    paymentAmount:
                      fieldValue === PetitionPaymentTypeId.BY_FIXED
                        ? c.paymentAmount
                        : undefined,
                  }
                : c;
          }
        }),
      );
    },
    onCheck: (typeId, paymentRequired) => {
      setTableKey(tableKey + 1);
      setPetitionTypesState((prev) =>
        prev.map((c) =>
          c.id === typeId
            ? {
                ...c,
                paymentRequired,
                paymentAmount: paymentRequired ? c.paymentAmount : undefined,
                paymentType: paymentRequired ? c.paymentType : undefined,
              }
            : c,
        ),
      );
    },
  });

  return (
    <div>
      <p className="fs-16 fw-600 text-primary mb-0">
        {t('petitionTypes.title')}
      </p>
      <p className="fs-14 mb-3">{t('petitionTypes.description')}</p>
      <Table key={tableKey} columns={columns} data={petitionTypesState} />
    </div>
  );
}

export default FinantialOtherPetitions;
