import {
  Button,
  Collapse,
  ColumnTable,
  Table,
  TextOutlinedInput,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StudyPlanVersion,
  StudyPlanVersionOffer,
} from '../../../../../types/studyPlan';
import {
  TariffPaymentMethodSettingValue,
  TuitionPaymentType,
} from '../../../../../types/tariffTypes';
import { moneyFormatter, TariffFormatter } from '../../../../../utils/currency';
import CustomBadge from './TariffCustomBadge';

/**
 * Interface para los inputs del formulario
 * nuevos valores son string
 * valores de la bd son number
 */
interface IForm {
  [key: string]: string;
}

interface ISubmitForm {
  offers: {
    tariff: string;
    credit?: string;
  }[];
}

export default function TariffAccordion({
  row,
  formData,
  fetchedConfigs,
}: {
  row: StudyPlanVersion;
  formData: (details: any[]) => void;
  fetchedConfigs?: TariffPaymentMethodSettingValue | null;
}) {
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<IForm>({
    mode: 'onChange',
  });

  const finalRow = useCallback((data) => data!.length - 1, []);

  const onSubmit = useCallback(
    (data: ISubmitForm) => {
      const checkValue = (value: string): number | null => {
        return value === '' ? null : Number(value.replace(/\D/g, ''));
      };

      /**
       * El indice de los formularios contienen el
       * academicOfferStudyPlanVersionOfferId (offerId).
       */
      formData(
        data.offers.map((inputs, offerId) => {
          const offer = row.offers.find((myOffer) => myOffer.id === offerId);

          return {
            tariffId: offer!.currentTariff?.id,
            tariffValue: checkValue(inputs.tariff),
            creditValue: inputs?.credit
              ? checkValue(inputs?.credit)
              : offer!.currentTariff?.credit,
            academicOfferStudyPlanVersionOfferId: offer!
              .academicOfferStudyPlanVersionOfferId,
            studyPlanName: row.name,
            campusName: offer!.campus!.name,
            scheduleName: offer!.schedule!.name,
          };
        }),
      );
    },
    [formData, row],
  );

  const printClassName = (
    index: number,
    data: Partial<StudyPlanVersionOffer>[],
    className?: string,
  ) => {
    return clsx(className, index === data.length - 1 && 'bg-white');
  };

  const columns: ColumnTable<Partial<StudyPlanVersionOffer>>[] = useMemo(
    () => [
      {
        columnName: 'campusName',
        bold: false,
        headerText: t('tariffAndTuition.tariff.table.campus'),
        width: '20%',
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: (options) => {
          return <p>{options.row.campus?.name}</p>;
        },
      },
      {
        columnName: 'schedule',
        headerText: t('tariffAndTuition.tariff.table.schedule'),
        width: '20%',
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: (options) => {
          return <p>{options.row.schedule?.name}</p>;
        },
      },
      {
        columnName: 'newTariff',
        headerText: t('tariffAndTuition.tariff.table.newTariff'),
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: ({ row: offer, index, data }) => {
          if (finalRow(data) === index) {
            return (
              <Button
                type="submit"
                text={t('tariffAndTuition.tariff.form.submitButton')}
                size="sm"
                loading={formState.isSubmitSuccessful}
                fullwidth
              />
            );
          }
          const inputName = `offers[${offer.id}].tariff`;
          return (
            <TextOutlinedInput
              textAlign="center"
              type="text"
              name={inputName}
              defaultValue={TariffFormatter(
                offer.currentTariff?.value,
                offer.currentTariff?.currency,
              )}
              formatter={(value) =>
                TariffFormatter(value, offer.currentTariff?.currency)
              }
              rules={{
                validate: {
                  checkprevius: (value: string) => {
                    if (
                      value.replace(/\D/g, '') === '' &&
                      typeof offer.currentTariff?.id === 'number'
                    ) {
                      return 'El campo es obligatorio';
                    }
                    return undefined;
                  },
                },
              }}
              control={control}
            />
          );
        },
      },
    ],
    [t, control, finalRow, formState.isSubmitSuccessful],
  );

  const renderColumns = useMemo((): ColumnTable<
    Partial<StudyPlanVersionOffer>
  >[] => {
    if (
      fetchedConfigs?.tuitionPaymentType ===
      TuitionPaymentType.TuitionContractCredits
    ) {
      return [
        ...columns,
        {
          columnName: 'newCredit',
          headerText: t('tariffAndTuition.tariff.table.newCredit'),
          tdClassName: ({ index, data }) => printClassName(index, data!),
          cellFormat: ({ row: offer, index, data }) => {
            if (finalRow(data) === index) {
              return (
                <Button
                  type="submit"
                  text={t('tariffAndTuition.tariff.form.submitButtonCredit')}
                  size="sm"
                  loading={formState.isSubmitSuccessful}
                  fullwidth
                />
              );
            }
            const inputName = `offers[${offer.id}].credit`;
            return (
              <TextOutlinedInput
                textAlign="center"
                type="text"
                name={inputName}
                // defaultValue={TariffFormatter(offer.currentTariff?.credit)}
                // formatter={(value) =>
                //   TariffFormatter(value, offer.currentTariff?.currency)
                // }
                defaultValue={
                  typeof offer.currentTariff?.credit === 'number'
                    ? moneyFormatter().format(offer.currentTariff?.credit)
                    : ''
                }
                formatter={formatter}
                rules={{
                  validate: {
                    checkprevius: (value: string) => {
                      if (
                        value.replace(/\D/g, '') === '' &&
                        typeof offer.currentTariff?.id === 'number'
                      ) {
                        return 'El campo es obligatorio';
                      }
                      return undefined;
                    },
                  },
                }}
                control={control}
              />
            );
          },
        },
        {
          columnName: 'status',
          headerText: t('tariffAndTuition.tariff.table.status'),
          width: '20%',
          tdClassName: ({ index, data }) =>
            printClassName(index, data!, 'text-center'),
          cellFormat: ({ row: offer, index, data }) => {
            return (
              index !== finalRow(data) && (
                <CustomBadge
                  value={
                    typeof offer.currentTariff?.value !== 'number' &&
                    typeof offer.currentTariff?.credit !== 'number'
                      ? undefined
                      : 1
                  }
                />
              )
            );
          },
        },
      ];
    }

    return [
      ...columns,
      {
        columnName: 'status',
        headerText: t('tariffAndTuition.tariff.table.status'),
        width: '20%',
        tdClassName: ({ index, data }) =>
          printClassName(index, data!, 'text-center'),
        cellFormat: ({ row: offer, index, data }) => {
          return (
            index !== finalRow(data) && (
              <CustomBadge value={offer.currentTariff?.value} />
            )
          );
        },
      },
    ];
  }, [
    t,
    control,
    columns,
    fetchedConfigs?.tuitionPaymentType,
    finalRow,
    formState.isSubmitSuccessful,
  ]);

  const formatter = (value: string) => {
    if (value === '$') return '';
    return moneyFormatter().format(Number(value.replace(/\D/g, '')));
  };

  const badgeValue = useMemo(
    () =>
      fetchedConfigs?.tuitionPaymentType ===
      TuitionPaymentType.TuitionContractCredits
        ? row.offers.some(
            (offer) =>
              typeof offer.currentTariff?.value !== 'number' ||
              typeof offer.currentTariff?.credit !== 'number',
          )
        : row.offers.some(
            (offer) => typeof offer.currentTariff?.value !== 'number',
          ),
    [row.offers, fetchedConfigs?.tuitionPaymentType],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} key={row.id}>
      <Collapse
        renderTitle={() => <p className="fs-14 text-dark fw-400">{row.name}</p>}
        renderBtn={({ isOpened, toggle }) => (
          <>
            <CustomBadge
              className={'ml-auto'}
              value={badgeValue ? undefined : 1}
            />
            <Collapse.ToggleButton
              iconName={isOpened ? 'minus' : 'plus'}
              toggle={toggle}
              btnClassName="bg-primary ml-3"
              iconColor="white"
            />
          </>
        )}
      >
        <Table
          columns={renderColumns}
          data={[...row.offers, {}]}
          striped
          color="transparent"
          borderless={false}
        />
      </Collapse>
    </form>
  );
}
