import { Table } from '@octano/global-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../locales/translations';
import useColumns from '../hooks/useColumns';
import { useFinantialOthersState } from '../hooks/useFinantialOthersState';

function FinantialOtherCertificates() {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );
  const {
    certificateTypesState,
    setCertificateTypesState,
  } = useFinantialOthersState();

  const [tableKey, setTableKey] = useState(0);

  const columns = useColumns({
    translateKey: 'certificateTypes',
    onChangeValue: (typeId, fieldName, fieldValue) => {
      setCertificateTypesState((prev) =>
        prev.map((c) =>
          c.id === typeId ? { ...c, [fieldName]: fieldValue } : c,
        ),
      );
    },
    onCheck: (typeId, paymentRequired) => {
      setTableKey(tableKey + 1);
      setCertificateTypesState((prev) =>
        prev.map((c) =>
          c.id === typeId
            ? {
                ...c,
                paymentRequired,
                paymentAmount: paymentRequired ? c.paymentAmount : undefined,
              }
            : c,
        ),
      );
    },
  });
  return (
    <div>
      <p className="fs-16 fw-600 text-primary mb-0">
        {t('certificateTypes.title')}
      </p>
      <p className="fs-14 mb-3">{t('certificateTypes.description')}</p>
      <Table key={tableKey} columns={columns} data={certificateTypesState} />
    </div>
  );
}

export default FinantialOtherCertificates;
