import request from '../../../api/request';

export function finantialOthersFormDataRequest() {
  return request<{
    certificateTypes: any[];
    petitionTypes: any;
    petitionPaymentTypes: any[];
  }>(
    '/maintainers-portal/general-configuration/finantial-configuration-others/form-data',
  );
}

export function finantialOthersUpdateRequest(data: any) {
  return request(
    '/maintainers-portal/general-configuration/finantial-configuration-others',
    {
      method: 'PUT',
      data,
    },
  );
}
