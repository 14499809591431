import {
  Button,
  Collapse,
  ColumnTable,
  Table,
  TextOutlinedInput,
} from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import {
  TariffPaymentMethodSettingValue,
  TuitionPaymentType,
} from '../../../../types/tariffTypes';
import {
  TariffListResponse,
  TcTariffSubmitData,
  TcTariffSubmitPendingData,
  TcTariffTableData,
} from '../../../../types/tuitionContinuityTrariff';
import { moneyFormatter, TariffFormatter } from '../../../../utils/currency';
import CustomBadge from '../../../AdmissionAndEnrollments/TariffAndTuition/Tariff/parts/TariffCustomBadge';

interface TcTariffAccordionProps {
  row: TcTariffTableData;
  onFormatData: (data: {
    data: TcTariffSubmitData[];
    pending: TcTariffSubmitPendingData[];
  }) => void;
  periodId: number;
  isLoading: boolean;
  texts: {
    accordion: Record<string, string>;
  };
  fetchedConfigs?: TariffPaymentMethodSettingValue | null;
}

interface IForm {
  [key: string]: string;
}

interface ISubmitForm {
  offers: {
    tariff: string;
    credit?: string;
  }[];
}

export default function TcTariffAccordion({
  row,
  onFormatData,
  periodId,
  isLoading,
  texts,
  fetchedConfigs,
}: TcTariffAccordionProps) {
  const { handleSubmit, control } = useForm<IForm>({
    mode: 'onChange',
  });

  const finalRow = useCallback((data) => data!.length - 1, []);
  const [globalStatus, setGlobalStatus] = useState<number | undefined>(1);

  const submitData = useCallback(
    (data: ISubmitForm) => {
      const checkValue = (value: string): number => {
        return value === '' ? 0 : Number(value.replace(/\D/g, ''));
      };

      /**
       * El indice de los formularios contienen el
       * academicOfferStudyPlanVersionOfferId (offerId).
       */
      const updatedOffer = data.offers.reduce(
        (acc, inputs, offerId) => {
          const offer = row.offers.find((x) => x.offerId === offerId);
          const finalValue = inputs.tariff ? checkValue(inputs.tariff) : 0;
          const creditValue = inputs?.credit ? checkValue(inputs.credit) : 0;

          if (finalValue > 0 || creditValue > 0) {
            acc.data.push({
              offerId: offerId,
              periodId,
              tariff: {
                id: offer?.tariff?.id,
                value: finalValue,
                credit: creditValue,
              },
              offerPeriodId: offer?.offerPeriodId,
            });
          } else if (offer) {
            acc.pending.push({
              campusName: offer.campusName,
              scheduleName: offer.scheduleName,
              studyPlanName: offer.studyPlanName,
            });
          }

          return acc;
        },
        {
          data: new Array<TcTariffSubmitData>(),
          pending: new Array<TcTariffSubmitPendingData>(),
        },
      );
      onFormatData(updatedOffer);
    },
    [onFormatData, periodId, row.offers],
  );

  useEffect(() => {
    for (const offer of row.offers) {
      if (!offer.tariff) {
        setGlobalStatus(undefined);
        break;
      }
    }
  }, [row.offers]);

  const printClassName = (
    index: number,
    data: Partial<TariffListResponse>[],
    className?: string,
  ) => {
    return clsx(className, index === data.length - 1 && 'bg-white');
  };

  const columns = useMemo<ColumnTable<Partial<TariffListResponse>>[]>(
    () => [
      {
        columnName: 'campusName',
        bold: false,
        headerText: texts.accordion.campus,
        width: '20%',
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: (options) => {
          return <p>{options.row.campusName}</p>;
        },
      },
      {
        columnName: 'schedule',
        headerText: texts.accordion.schedule,
        width: '20%',
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: (options) => {
          return <p>{options.row.scheduleName}</p>;
        },
      },
      {
        columnName: 'tariff',
        headerText: texts.accordion.tariff,
        width: '20%',
        tdClassName: ({ index, data }) => printClassName(index, data!),
        cellFormat: ({ row: offer, index, data }) => {
          if (finalRow(data) === index) {
            return (
              <Button
                type="submit"
                text={texts.accordion.submit}
                size="sm"
                loading={isLoading}
                fullwidth
              />
            );
          }
          const inputName = `offers[${offer.offerId}].tariff`;
          return (
            <TextOutlinedInput
              textAlign="center"
              type="text"
              name={inputName}
              defaultValue={TariffFormatter(
                offer.tariff?.value,
                offer.tariff?.currency,
              )}
              formatter={(value) =>
                TariffFormatter(value, offer.tariff?.currency)
              }
              control={control}
            />
          );
        },
      },
    ],
    [texts, finalRow, isLoading, control],
  );

  const renderColumns = useMemo((): ColumnTable<
    Partial<TariffListResponse>
  >[] => {
    if (
      fetchedConfigs?.tuitionPaymentType ===
      TuitionPaymentType.TuitionContractCredits
    ) {
      return [
        ...columns,
        {
          columnName: 'newCredit',
          headerText: texts.accordion.credit,
          tdClassName: ({ index, data }) => printClassName(index, data!),
          cellFormat: ({ row: offer, index, data }) => {
            if (finalRow(data) === index) {
              return (
                <Button
                  type="submit"
                  text={texts.accordion.submitButtonCredit}
                  size="sm"
                  loading={isLoading}
                  fullwidth
                />
              );
            }
            const inputName = `offers[${offer?.offerId}].credit`;
            return (
              <TextOutlinedInput
                textAlign="center"
                type="text"
                name={inputName}
                // defaultValue={TariffFormatter(
                //   offer.tariff?.credit,
                //   offer.tariff?.currency,
                // )}
                // formatter={(value) =>
                //   TariffFormatter(value, offer.tariff?.currency)
                // }
                defaultValue={
                  typeof offer.tariff?.credit === 'number'
                    ? moneyFormatter().format(offer.tariff?.credit)
                    : ''
                }
                formatter={formatter}
                control={control}
              />
            );
          },
        },
        {
          columnName: 'status',
          headerText: texts.accordion.status,
          width: '20%',
          tdClassName: ({ index, data }) =>
            printClassName(index, data!, 'text-center'),
          cellFormat: ({ row: offer, index, data }) => {
            return (
              index !== finalRow(data) && (
                <CustomBadge
                  value={
                    typeof offer.tariff?.value !== 'number' &&
                    typeof offer.tariff?.credit !== 'number'
                      ? undefined
                      : 1
                  }
                />
              )
            );
          },
        },
      ];
    }

    return [
      ...columns,
      {
        columnName: 'status',
        headerText: texts.accordion.status,
        width: '20%',
        tdClassName: ({ index, data }) =>
          printClassName(index, data!, 'text-center'),
        cellFormat: ({ row: offer, index, data }) => {
          return (
            index !== finalRow(data) && (
              <CustomBadge value={offer.tariff?.value} />
            )
          );
        },
      },
    ];
  }, [
    control,
    columns,
    texts,
    fetchedConfigs?.tuitionPaymentType,
    finalRow,
    isLoading,
  ]);

  const formatter = (value: string) => {
    if (value === '$') return '';
    return moneyFormatter().format(Number(value.replace(/\D/g, '')));
  };

  return (
    <Form key={row.id} onSubmit={handleSubmit(submitData)}>
      <Collapse
        renderTitle={() => <p className="fs-14 text-dark fw-400">{row.name}</p>}
        renderBtn={({ isOpened, toggle }) => (
          <>
            <CustomBadge value={globalStatus} className="ml-auto" />
            <Collapse.ToggleButton
              iconName={isOpened ? 'minus' : 'plus'}
              toggle={toggle}
              btnClassName="bg-primary ml-3"
              iconColor="white"
            />
          </>
        )}
      >
        <Table
          columns={renderColumns}
          data={[...row.offers, {}]}
          striped
          color="transparent"
          borderless={false}
        />
      </Collapse>
    </Form>
  );
}
