import { useState, useCallback } from 'react';
import { SemesterCourse } from '../../../components/curriculum/types';

export enum ValidationStep {
  None,
  ChooseValidationType,
  InternalValidation,
  RapSelected,
}

export type ValidationType = 'internal' | 'rap';

interface UseValidationFlowReturn {
  validationStep: ValidationStep;
  selectedCourse: { id: number; name: string } | null;
  selectedValidationType: ValidationType | null;
  onCourseClick: (semesterCourse: SemesterCourse) => void;
  selectValidationData: (data: { validationType: ValidationType }) => void;
  resetFlow: () => void;
}

export function useValidationFlow(): UseValidationFlowReturn {
  const [selectedCourse, setSelectedCourse] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [validationStep, setValidationStep] = useState<ValidationStep>(
    ValidationStep.None,
  );
  const [
    selectedValidationType,
    setSelectedValidationType,
  ] = useState<ValidationType | null>(null);

  // Al hacer click en un curso se guarda la información y se abre el modal para elegir el tipo de validación
  const onCourseClick = useCallback((semesterCourse: SemesterCourse) => {
    if (semesterCourse.state && semesterCourse.state !== 'R') {
      return;
    }
    const courseName = semesterCourse.course
      ? semesterCourse.course.name
      : semesterCourse.courseType.name;
    setSelectedCourse({ id: semesterCourse.id, name: courseName });
    setValidationStep(ValidationStep.ChooseValidationType);
  }, []);

  // Se recibe el objeto con la selección combinada
  const selectValidationData = useCallback(
    (data: { validationType: ValidationType }) => {
      setSelectedValidationType(data.validationType);
      if (data.validationType === 'internal') {
        setValidationStep(ValidationStep.InternalValidation);
      } else if (data.validationType === 'rap') {
        setValidationStep(ValidationStep.RapSelected);
      }
    },
    [],
  );

  const resetFlow = useCallback(() => {
    setSelectedCourse(null);
    setSelectedValidationType(null);
    setValidationStep(ValidationStep.None);
  }, []);

  return {
    validationStep,
    selectedCourse,
    selectedValidationType,
    onCourseClick,
    selectValidationData,
    resetFlow,
  };
}
