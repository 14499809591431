import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';

import GoBackToListButton from '../../components/buttons/GoBackToListButton';
import {
  CoursesBySemesters,
  Legends,
  Resume,
} from '../../components/curriculum';
import { LegendsProps } from '../../components/curriculum/Legends';
import { ResumeProps } from '../../components/curriculum/Resume';
import { SectionTitle } from '../../components/text';
import { PathsLayouts } from '../../config/routes';
import { isNumber } from '../../utils/math';
import BaseLoader from './parts/BaseLoader';
import CurriculumLoader from './parts/CurriculumLoader';
import Modal from './parts/Modal';
import ModalNoCourses from './parts/ModalNoCourses';
import StudyPlanBox from './parts/StudyPlanBox';
import ModalValidationType from './parts/ModalValidationType';
import { useValidationFlow, ValidationStep } from './hooks/useValidationFlow';

export default function CourseValidationInternalStudent() {
  const prefix = 'courseValidationInternalStudent';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      body: t(`${prefix}.body`),
      resumeTitle: t(`${prefix}.resume.title`),
      curriculumTitle: t(`${prefix}.curriculum.title`),
      semester: t(`${prefix}.curriculum.semester`),
      credits: t(`${prefix}.curriculum.credits`),
      box: {
        student: t(`${prefix}.box.student`),
        rut: t(`${prefix}.box.rut`),
        studyPlan: t(`${prefix}.box.studyPlan`),
      },
      resume: {
        ...t<string, ResumeProps['texts']>(`${prefix}.resume`, {
          returnObjects: true,
        }),
        durationValue: (duration: number, periodsType: string) =>
          t(`${prefix}.resume.durationValue`, { count: duration, periodsType }),
      },
      curriculumStatus: t<string, LegendsProps['texts']>(
        `${prefix}.curriculum.status`,
        {
          returnObjects: true,
        },
      ),
    }),
    [t],
  );

  const { studyPlanEnrollmentId: studyPlanEnrollmentIdStr } = useParams<{
    studyPlanEnrollmentId: string;
  }>();

  const studyPlanEnrollmentId = useMemo(() => {
    if (isNumber(studyPlanEnrollmentIdStr)) {
      return Number(studyPlanEnrollmentIdStr);
    }
    return null;
  }, [studyPlanEnrollmentIdStr]);

  // hook para manejar el flujo de validación
  const {
    validationStep,
    selectedCourse,
    onCourseClick,
    selectValidationData,
    resetFlow,
  } = useValidationFlow();

  if (!studyPlanEnrollmentId) {
    return <Redirect to={PathsLayouts.courseValidationInternal} />;
  }

  return (
    <Card className="p-4 mt-2 mx-3">
      <BaseLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
        {(data) => (
          <>
            <Row>
              <Col xs={12} sm={3} className="pb-2">
                <GoBackToListButton
                  path={PathsLayouts.courseValidationInternal}
                />
              </Col>
              <Col xs={12} sm={9} className="pb-2">
                <StudyPlanBox
                  studentName={`${data.student.lastnames}, ${data.student.name}`}
                  studentRut={data.student.rut}
                  studyPlanName={data.studyPlanVersion?.name}
                  texts={texts.box}
                />
              </Col>
            </Row>
            <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
            <CurriculumLoader studyPlanEnrollmentId={studyPlanEnrollmentId}>
              {({ data: curriculumData, refresh }) => (
                <>
                  {/* DATOS DE LA MALLA */}
                  <div className="mb-4">
                    <div className="mb-3">
                      <SectionTitle text={texts.resumeTitle} />
                    </div>
                    <Resume texts={texts.resume} {...curriculumData} />
                  </div>

                  {/* MALLA DE ESTUDIOS */}
                  <div>
                    <div className="mb-3">
                      <SectionTitle text={texts.curriculumTitle} />
                    </div>
                    <div className="mb-3">
                      <CoursesBySemesters
                        semesters={curriculumData.semesters}
                        texts={texts}
                        onClick={onCourseClick}
                      />

                      {/* Modal para elegir el tipo de validación */}
                      {validationStep === ValidationStep.ChooseValidationType &&
                        selectedCourse && (
                          <ModalValidationType
                            isOpen
                            studyPlanEnrollmentId={studyPlanEnrollmentId}
                            semesterCourseId={selectedCourse.id}
                            courseName={selectedCourse?.name || ''}
                            onSelect={selectValidationData}
                            onClose={resetFlow}
                            onSuccess={() => {
                              resetFlow();
                              refresh();
                            }}
                          />
                        )}

                      {/* Convalidación interna */}
                      {validationStep === ValidationStep.InternalValidation &&
                        selectedCourse &&
                        (data.courses.length > 0 ? (
                          <Modal
                            studyPlanEnrollmentId={studyPlanEnrollmentId}
                            isOpen
                            semesterCourseToValidate={selectedCourse}
                            courseEnrollments={data.courses}
                            onClose={resetFlow}
                            onSuccess={() => {
                              resetFlow();
                              refresh();
                            }}
                          />
                        ) : (
                          <ModalNoCourses isOpen onClose={resetFlow} />
                        ))}
                    </div>
                    <Legends texts={texts.curriculumStatus} />
                  </div>
                </>
              )}
            </CurriculumLoader>
          </>
        )}
      </BaseLoader>
    </Card>
  );
}
