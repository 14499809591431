// Components
import { Select, SelectOptionType } from '@octano/global-ui';
// Hooks
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../locales/translations';
// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

const prefix = 'tuitionPaymentType';

// Render
export default function TuitionPaymentTypeSection() {
  const { t } = useTranslation(TranslationsKeys.CONFIGURATION_FINANTIAL);

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { msgValidations } = useValidations();

  const { watch, setValue } = useFormContext<FinantialConfig>();

  const [tuitionPaymentType] = watch(['tuitionPaymentType']);

  const tuitionPaymentTypeOptions = useMemo<SelectOptionType[]>(
    () =>
      finantialSettingsContext?.formData?.tuitionPaymentType?.map((e) => ({
        label: e.name,
        value: e.id,
      })) ?? [],
    [finantialSettingsContext?.formData?.tuitionPaymentType],
  );

  const tuitionPaymentTypeValue = useMemo<SelectOptionType | null>(
    () =>
      tuitionPaymentTypeOptions?.find((e) => e?.value === tuitionPaymentType) ??
      null,
    [tuitionPaymentType, tuitionPaymentTypeOptions],
  );

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <Select
          name="tuitionPaymentType"
          label={t(`${prefix}.inputs.tuitionPaymentType`)}
          options={tuitionPaymentTypeOptions}
          value={tuitionPaymentTypeValue ?? undefined}
          disabled={finantialSettingsContext.disabled}
          onChange={(e) => {
            setValue('tuitionPaymentType', e?.value);
          }}
          rules={{ required: msgValidations.required }}
        />
      </Col>
    </Row>
  );
}
