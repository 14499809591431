import { addToast, Icon } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, Col, Row } from 'reactstrap';
import { ViewStudentsPetition } from '../Received/types/petitionDetail';

export interface MultipleFileDownloadProps {
  files?: ViewStudentsPetition['docs'];
  label: string;
}

const prefix = 'studentPetitionDetail.common';
export default function MultipleFileDownload({
  files,
  label,
}: MultipleFileDownloadProps) {
  const { t } = useTranslation();

  const downloadFile = useCallback(
    (fileItem: { name: string; url: string }) => {
      const link = document.createElement('a');
      link.download = fileItem.name;
      link.href = fileItem.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.downloads.downloadSuccess`),
      });
    },
    [t],
  );

  const renderFileInfo = () => {
    if (files && files.length > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            color: 'black !important',
          }}
        >
          {files.map((file, idx) => (
            <Badge
              key={idx}
              className="fs-14 fw-400 py-2 px-3 bg-tertiary border border-secondary text-dark"
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '8px',
                width: 'auto',
                whiteSpace: 'normal',
              }}
              onClick={() => file && downloadFile(file)}
            >
              {file && file.name} <Icon name="download" size="16px" />
            </Badge>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Card>
      <label className="g-file-input-label text-left">{label}</label>
      <Row>
        <Col xs={12}>
          <div className="py-3">{renderFileInfo()}</div>
        </Col>
      </Row>
    </Card>
  );
}
