import { useMemo, useCallback, useState } from 'react';
import {
  Modal,
  Button,
  Select,
  TextInput,
  TextAreaInput,
  FileInputControlled,
  addToast,
} from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useValidatorNotes from '../../../hooks/useValidatorNotes';
import { CreateRapValidationData } from '../types';
import { createRapValidation } from '../requests';

interface ModalValidationTypeProps {
  isOpen: boolean;
  studyPlanEnrollmentId: number;
  semesterCourseId: number;
  courseName: string;
  onSelect: (data: {
    validationType: 'internal' | 'rap';
    rapOption?: 'validation' | 'proficiency';
  }) => void;
  onClose: () => void;
  onSuccess: () => void;
}

export default function ModalValidationType({
  isOpen,
  studyPlanEnrollmentId,
  semesterCourseId,
  courseName,
  onSelect,
  onClose,
  onSuccess,
}: ModalValidationTypeProps) {
  const { t } = useTranslation();
  const prefix = 'courseValidationInternalStudent.modalValidationType';
  const { formattertNote } = useValidatorNotes();
  const maxValidation = useCallback(
    (value: number) => ({
      value,
      message: t(`modal.form.validation.max`, { value }),
    }),
    [t],
  );

  const texts = useMemo(
    () => ({
      title: t(`${prefix}.title`),
      subTitle: t(`${prefix}.subTitle`),
      courseNameLabel: t(`${prefix}.courseNameLabel`),
      examGradeLable: t(`${prefix}.examGradeLabel`),
      courseGradeLabel: t(`${prefix}.courseGradeLabel`),
      invalidGrade: t(`${prefix}.invalidGrade`),
      description: {
        label: t('common.forms.description'),
        placeholder: t(`common.forms.descriptionPlaceholder`),
      },
      validationType: {
        selectLabel: t(`${prefix}.validationType.selectLabel`),
        internal: t(`${prefix}.validationType.internal`),
        rap: t(`${prefix}.validationType.rap`),
      },
      rapType: {
        selectLabel: t(`${prefix}.rapType.selectLabel`),
        validation: t(`${prefix}.rapType.validation`),
        proficiency: t(`${prefix}.rapType.proficiency`),
      },
      uploadFile: {
        label: t(`${prefix}.uploadFile.label`),
        button: t(`${prefix}.uploadFile.button`),
      },
      actions: {
        cancel: t(`${prefix}.actions.cancel`),
        confirm: t(`${prefix}.actions.confirm`),
      },
      required: t('common.validations.required'),
      toast: {
        success: t(`${prefix}.toast.success`),
        error: t(`${prefix}.toast.error`),
      },
    }),
    [t],
  );

  const validationOptions = [
    { label: texts.validationType.internal, value: 'internal' },
    { label: texts.validationType.rap, value: 'rap' },
  ];

  const rapOptions = [
    { label: texts.rapType.validation, value: 'validation' },
    { label: texts.rapType.proficiency, value: 'proficiency' },
  ];

  const { handleSubmit, watch, control } = useForm<{
    validationType: { label: string; value: 'internal' | 'rap' };
    rapOption?: { label: string; value: 'validation' | 'proficiency' };
    examGrade?: string;
    courseGrade?: string;
    description?: string;
    doc?: File;
  }>({
    defaultValues: {
      validationType: {
        label: texts.validationType.internal,
        value: 'internal',
      },
    },
  });

  const watchValidation = watch('validationType');
  const selectedValidation = watchValidation?.value;

  const watchRapOption = watch('rapOption');
  const selectedRapOption = watchRapOption?.value;
  const isProficiency = selectedRapOption === 'proficiency';
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (formData: {
    validationType: { value: 'internal' | 'rap' };
    rapOption?: { value: 'validation' | 'proficiency' };
    examGrade?: string;
    courseGrade?: string;
    description?: string;
    doc?: File;
  }) => {
    if (formData.validationType.value === 'rap') {
      setLoading(true);
      const rapData: CreateRapValidationData = {
        semesterCourseId,
        validationType: formData.rapOption?.value!,
        examGrade: formData.examGrade || '',
        courseGrade: formData.courseGrade!,
        description: formData.description || '',
        doc: formData.doc,
      };
      try {
        const response = await createRapValidation(
          studyPlanEnrollmentId,
          rapData,
        );
        if (response.error) {
          addToast({
            icon: 'success',
            color: 'success',
            text: texts.toast.error,
          });
          setLoading(false);
          return;
        } else {
          addToast({
            icon: 'success',
            color: 'success',
            text: texts.toast.success,
          });
          setLoading(false);
          onClose();
          onSuccess();
        }
      } catch (error) {
        setLoading(false);
        console.error('Error enviando validación RAP:', error);
      }
    } else {
      onSelect({ validationType: formData.validationType.value });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} closeOnBackdrop={true} size="lg">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-black fs-22 text-center">{texts.title}</div>
        <div className="text-black fs-16 text-center mb-5">
          {texts.subTitle}
        </div>

        <Row className="mb-3">
          <Col xs={12}>
            <TextInput
              name="courseName"
              label={texts.courseNameLabel}
              value={courseName}
              disabled
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12}>
            <Select
              name="validationType"
              label={texts.validationType.selectLabel}
              options={validationOptions}
              control={control}
              rules={{
                required: texts.required,
              }}
            />
          </Col>
        </Row>

        {selectedValidation === 'rap' && (
          <Row className="mb-3">
            <Col xs={12}>
              <Select
                name="rapOption"
                label={texts.rapType.selectLabel}
                options={rapOptions}
                control={control}
                rules={{
                  required: texts.required,
                }}
              />
            </Col>
          </Row>
        )}

        {selectedRapOption && (
          <Row className="mb-3">
            <Col xs={12} md={isProficiency ? 6 : 12}>
              <TextInput
                name="courseGrade"
                label={texts.courseGradeLabel}
                control={control}
                rules={{
                  required: texts.required,
                  validate: (value: any) => {
                    if (!value) return false;
                    return formattertNote(value) !== '' || texts.invalidGrade;
                  },
                }}
              />
            </Col>
            {isProficiency && (
              <Col xs={12} md={6}>
                <TextInput
                  name="examGrade"
                  label={texts.examGradeLable}
                  control={control}
                  rules={{
                    required: texts.required,
                    validate: (value: any) => {
                      if (!value) return false;
                      return formattertNote(value) !== '' || texts.invalidGrade;
                    },
                  }}
                />
              </Col>
            )}
            <Col xs={12}>
              <TextAreaInput
                label={texts.description.label}
                name="description"
                placeholder={texts.description.placeholder}
                rules={{
                  maxLength: maxValidation(500),
                }}
                control={control}
              />
            </Col>
            <Col xs={12} className="py-4">
              <FileInputControlled
                name="doc"
                btnText={texts.uploadFile.button}
                label={texts.uploadFile.label}
                accept="image/png, image/jpeg, application/pdf"
                control={control}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={6}>
            <Button
              onClick={onClose}
              text={texts.actions.cancel}
              outlined
              fullwidth
            />
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              text={texts.actions.confirm}
              fullwidth
              loading={loading}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
