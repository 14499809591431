export enum PetitionTypeId {
  ACADEMIC = 'ACD',
  PAYMENT = 'PAY',
  REPLACE_EVALUATION_GRADE = 'REG',
  OTHER = 'OTH',
  REPLACE_COURSE_GRADE = 'RCG',
}

export enum PetitionPaymentTypeId {
  BY_CREDIT = 'CREDIT',
  BY_FIXED = 'FIXED',
}

export interface PetitionType {
  id: PetitionTypeId;
  name: string;
  paymentRequired?: boolean | null;
  paymentAmount?: number | null;
  paymentType?: PetitionPaymentTypeId | null;
}

export interface CertificateType {
  id: number;
  name: string;
  paymentRequired?: boolean | null;
  paymentAmount?: number | null;
}

export interface FinantialOthersFormDataResponse {
  certificateTypes: CertificateType[];
  petitionTypes: PetitionType[];
  petitionPaymentTypes: { name: string; id: PetitionPaymentTypeId }[];
}
