import {
  ColumnTable,
  SelectOptionType,
  OutlinedSelect,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';
import { useFinantialOthersLoader } from '../parts/FinantialOthersLoader';
import {
  CertificateType,
  PetitionPaymentTypeId,
  PetitionType,
  PetitionTypeId,
} from '../type';

function useColumns({
  onCheck,
  onChangeValue,
  showPaymentType = false,
  translateKey,
}: {
  onCheck: (typeId: number | PetitionTypeId, value: boolean) => void;
  onChangeValue: (
    typeId: number | PetitionTypeId,
    fieldName: string,
    fieldValue: number | string,
  ) => void;
  showPaymentType?: boolean;
  translateKey: 'petitionTypes' | 'certificateTypes';
}) {
  const { t } = useTranslation(
    TranslationsKeys.CONFIGURATION_FIANANTIAL_OTHERS,
  );

  const finantialSettingsOthersContext = useFinantialOthersLoader();
  const petitionPaymentTypeOptions = useMemo<SelectOptionType[]>(
    () =>
      finantialSettingsOthersContext.data?.petitionPaymentTypes?.map((e) => ({
        label: e.name,
        value: e.id,
      })) ?? [],
    [finantialSettingsOthersContext.data?.petitionPaymentTypes],
  );

  return useMemo<ColumnTable<PetitionType | CertificateType>[]>(
    () => [
      {
        columnName: 'name',
        headerText: t(`${translateKey}.table.name`),
        tdClassName: 'text-left',
        thClassName: 'text-left',
      },
      {
        columnName: 'paymentRequired',
        headerText: t(`${translateKey}.table.paymentRequirement`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '20%',
        cellFormat: (options) => {
          return (
            <>
              <div className="form-group" style={{ marginTop: '-15px' }}>
                <label className="g-checkbox-input-container">
                  <Input
                    className="g-checkbox-input"
                    type="checkbox"
                    defaultChecked={Boolean(options.row.paymentRequired)}
                    onChange={() => {
                      onCheck(
                        options.row.id,
                        !Boolean(options.row.paymentRequired),
                      );
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <small className="g-input-error form-text text-danger"></small>
              </div>
            </>
          );
        },
      },
      ...(showPaymentType
        ? [
            {
              columnName: 'paymentType',
              headerText: t(`${translateKey}.table.paymentType`),
              tdClassName: 'text-center',
              thClassName: 'text-center',
              width: '20%',
              cellFormat: (options: any) => {
                if (!options.row.paymentRequired) {
                  return <></>;
                }

                const defaultValue = petitionPaymentTypeOptions.find(
                  (option) =>
                    option.value ===
                    (options.value ?? PetitionPaymentTypeId.BY_FIXED),
                );
                return (
                  <>
                    <div className="form-group">
                      <OutlinedSelect
                        name="paymentType"
                        label={''}
                        options={petitionPaymentTypeOptions}
                        value={defaultValue}
                        onChange={(e) => {
                          onChangeValue(options.row.id, 'paymentType', e.value);
                        }}
                        isClearable={false}
                        height="100%"
                      />
                    </div>
                  </>
                );
              },
            },
          ]
        : []),
      {
        columnName: 'paymentAmount',
        headerText: t(`${translateKey}.table.paymentAmount`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: '20%',
        cellFormat: (options) => {
          const isDisabled =
            !Boolean(options.row.paymentRequired) ||
            ('paymentType' in options.row &&
              options.row.paymentType === PetitionPaymentTypeId.BY_CREDIT);

          return (
            <div className="form-group">
              <Input
                style={{ height: '38px', textAlign: 'center' }}
                name="paymentAmount"
                className="border-primary"
                type="number"
                disabled={isDisabled}
                defaultValue={options.value}
                onChange={(e) => {
                  onChangeValue(options.row.id, e.target.name, e.target.value);
                }}
              />
            </div>
          );
        },
      },
    ],
    [
      onCheck,
      onChangeValue,
      t,
      translateKey,
      petitionPaymentTypeOptions,
      showPaymentType,
    ],
  );
}

export default useColumns;
